import { Controller } from '@hotwired/stimulus'
import {
  init,
  setUser,
  showReportDialog,
  feedbackIntegration,
} from '@sentry/browser'
export default class extends Controller {
  static values = {
    sentryId: String,
    userName: String,
    userEmail: String,
  }
  connect() {
    if (document.documentElement.hasAttribute('data-turbo-visit-direction')) {
      return
    }

    setUser({
      email: this.userNameValue,
      fullName: this.userEmailValue,
    })

    showReportDialog({
      eventId: this.sentryIdValue,
      name: this.userNameValue,
      email: this.userEmailValue,
    })

    init({
      dsn: 'https://c527256749834b0b893c6702c96b522d@sentry.airshaper.com/2',
      integrations: [
        feedbackIntegration({
          showBranding: false,
          colorScheme: 'light',
          useSentryUser: {
            email: 'email',
            name: 'fullName',
          },
        }),
      ],
    })
  }
}
